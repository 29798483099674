const SUPPORTED_SOCIAL_IDPS = [
  'facebook',
  'google',
  'linkedin',
  'microsoft',
  'apple',
  'github',
  'gitlab',
  'yahoo',
  'line',
  'paypal',
  'paypal_sandbox',
  'salesforce',
  'amazon',
  'yahoo_japan',
];
export default {
  SUPPORTED_SOCIAL_IDPS,
};
